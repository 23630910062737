import { admin } from "../../ServiceConfig";


// gift Card Theme --------------------------------
export function uploadGiftCardPreFilledImage(file) {
  return admin.post(`/uploads/image?upload_path=gift-cards/prefilled`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function uploadGiftCardBlankImage(file) {
  return admin.post(`/uploads/image?upload_path=gift-cards/blank`, file, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function addGiftCardTheme(value) {
  return admin.post(`/store/category-theme`, value);
}

export function updateGiftCardTheme(value) {
  return admin.put(`/update/category-theme/${value.id}`, value);
}

export function getGiftCardThemes() {
  return admin.get(`/category-theme`);
}

export function deleteGiftCard(id) {
  return admin.delete(`/delete/category-theme/${id}`);
}

export function getGiftCardThemeById(id) {
  return admin.get(`/category-theme/${id}`);
}

export function getGiftCardThemeByCategoryId(id) {
  return admin.get(`/themes/category/${id}`);
}

// Gift card category -------------------------
export function addGiftCardCategory(value) {
  return admin.post(`/gift-card/category`, value);
}
export function updateOrderOfGiftCardCategory(value) {
  return admin.post(`/gift-card/category/update-order`, value);
}

export function getGiftcardCategoryById(id) {
  return admin.get(`gift-card/category/${id}`)
}

export function deleteGiftCardCategory(id) {
  return admin.delete(`/gift-card/category/${id}`);
}

export function updateGiftcardCategory(id, value) {
  return admin.put(`/gift-card/category/${id}`, value)
}

export function getGiftCardCategories() {
  return admin.get(`/gift-card/category`);
}

export function saveGiftCardCategory(id) {
  return admin.post(`/gift-card/category/${id}`);
}



// Issued gift Cards ----------------------------

export function getGiftCardbyId(id) {
  return admin.get(`/giftCard/${id}`)
}

export function addGiftCardIssued(data) {
  return admin.post(`/giftCard`, data)
}

export function updateGiftCardIssued(id, data) {
  return admin.put(`/giftCard/${id}`, data)
}

export function uploadBulkGiftCard(data){
  return admin.post(`bulk-generate/giftCard`, data)
}