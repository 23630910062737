import React, { useEffect, useState } from 'react'


import { FaTrash } from 'react-icons/fa'
import { toast } from 'react-toastify'
import { deleteTravelMode, getAllTravelModes } from '../../services/TravelModeServices'
import SelectSearch from '../inputs/SelectSearch'

const TravelModeDeleteModal = ({ selectedTravelMode, travelModeTable, setOpenTravelModeChangeModal }) => {
    const [allTravelModes, setAllTravelModes] = useState([])
    const [selected, setSelected] = useState()
    const [selectedRider, setSelectedRider] = useState()
    const allTravelModesGetter = async () => {
        const res = await getAllTravelModes()
        const arrayOfCats = res.data.data.data.map((oneCat) => ({
            label: oneCat?.name,
            value: oneCat.id
        }))
        setAllTravelModes(arrayOfCats.filter((oneCat) => oneCat.value !== selectedTravelMode))
    }
    useEffect(() => {
        allTravelModesGetter()
    }, [])

    const onDelete = () => {
        console.log('selectedTravelMode: ', selectedTravelMode.id)
        console.log('selected: ', selected)
        // alert('this will rplace the current travel mode with selected one and remove the current');
        deleteTravelMode(selectedTravelMode.id, { new: selected, rider_id: selectedRider })

            .then((res) => {
                travelModeTable.current.reloadTable();
                setOpenTravelModeChangeModal(false)
                toast.success("Deleted Successfully")
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            });
    }
    return (
        <div className='flex lg:flex-row flex-col lg:gap-4 gap-1 items-center'>
            <div className='min-w-[300px]'>
                <label>Select Travel Mode </label>
                <SelectSearch
                    placeholder=""
                    className='dark:text-black'
                    value={selected
                    }
                    options={allTravelModes}
                    onChange={(value) => {
                        setSelected(value)
                    }
                    }
                />
            </div>

            <div className='min-w-[300px]'>
                <label>Only Select In case Non rider TM to has rider TM</label>
                <SelectSearch
                    placeholder=""
                    className='dark:text-black'
                    value={selectedRider
                    }
                    options={
                        [
                            {
                                value: 1, label: "Dual Rider"
                            },
                            {
                                value: 2, label: "Solo Rider"
                            }
                        ]
                    }
                    onChange={(value) => {
                        setSelectedRider(value)
                    }
                    }
                />
            </div>
            <div>
                <label>&nbsp;</label>
                <button
                    disabled={!selected}
                    onClick={onDelete}
                    className="flex items-center lg:my-4 my-1 justify-center disabled:bg-opacity-50 p-2 bg-red-500 text-white rounded hover:bg-red-600"
                >
                    <FaTrash className="w-4 h-4" /> Delete
                </button>
            </div>
        </div>
    )
}

export default TravelModeDeleteModal