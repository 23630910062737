import { useRef, useEffect, forwardRef, useImperativeHandle } from "react";
import {
  FaDownload,
  FaFileCsv,
  FaFileExcel,
  FaFilePdf,
  FaPlusSquare,
} from "react-icons/fa";

import {
  Tabulator,
  FormatModule,
  EditModule,
  ResizeColumnsModule,
  SortModule,
  PageModule,
  FilterModule,
  GroupRowsModule,
  DownloadModule,
  ExportModule,
  AjaxModule,
  MoveColumnsModule,
  ResizeTableModule,
  FrozenColumnsModule,
  InteractionModule,
  ResponsiveLayoutModule,
  ValidateModule,
  DataTreeModule,
  SelectRowModule,
  MoveRowsModule,
} from "tabulator-tables";
import { loadScript } from "../../../helpers/Helper";
import { memo } from "react";

Tabulator.registerModule([
  FormatModule,
  EditModule,
  ResizeColumnsModule,
  SortModule,
  PageModule,
  FilterModule,
  GroupRowsModule,
  DownloadModule,
  ExportModule,
  AjaxModule,
  MoveColumnsModule,
  MoveRowsModule,
  InteractionModule,
  ResizeTableModule,
  FrozenColumnsModule,
  ResponsiveLayoutModule,
  ValidateModule,
  DataTreeModule,
  SelectRowModule,
]);

const DataTable = forwardRef((props, ref) => {
  const table = useRef();
  const tableRef = useRef();
  const tableWrapperRef = useRef();

  const searchTimer = useRef();

  function customFilter(data, filterParams) {
    //data - the data for the row being filtered
    //filterParams - params object passed to the filter

    return data.name === "bob" && data.height < filterParams.height; //must return a boolean, true if it passes the filter.
  }

  const handleSearch = (e) => {
    clearTimeout(searchTimer.current);

    searchTimer.current = setTimeout(() => {
      if (props.localSearch) {
        table.current.setFilter(customFilter, { height: 3 });
      } else {
        table.current.setFilter("any", "like", e.target.value, {
          matchAll: true,
        });
      }
    }, 500);
  };

  useImperativeHandle(ref, () => ({
    getData() {
      if (table.current) {
        return table.current.getData();
      }
      return [];
    },
    deleteRow(rowId) {
      table.current.deleteRow(rowId);
    },
    updateTable() {
      table.current.setData();
    },

    getSelectedData() {
      return table.current.getSelectedData();
    },
    getColumns() {
      return table.current.getColumns();
    },

    reloadTable(url) {
      table.current.setData(
        url,
        {},
        {
          method: "GET",
          headers: {
            authorization: `Bearer ${localStorage.getItem("admin-token")}`,
          },
        }
      );
    },

    setFilter(filter) {
      table.current.setFilter(filter);
    },
    download(type, name, options) {
      table.current.download(type, name, options);
    },
  }));

  useEffect(() => {
    loadScript(
      "https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.4.0/jspdf.umd.min.js"
    ).then((res) => {
      loadScript(
        "https://cdnjs.cloudflare.com/ajax/libs/jspdf-autotable/3.5.20/jspdf.plugin.autotable.min.js"
      );
    });

    loadScript("https://oss.sheetjs.com/sheetjs/xlsx.full.min.js");

    table.current = new Tabulator(
      tableRef.current,
      {
        ajaxURL: props.ajaxUrl,
        ajaxConfig: {
          method: "GET",
          headers: {
            authorization: `Bearer ${localStorage.getItem("admin-token")}`,
          },
        },
        ajaxResponse: function (_url, params, response) {
          response.data.last_page = response.data.meta.last_page;
          response.data.last_row = response.data.meta.total;
          return response.data;
        },
        data: props.tableData,
        dataTree: props?.dataTree ? props?.dataTree : false,

        headerFilterLiveFilterDelay: 500,
        sortMode: "remote",
        initialSort: props.initialSort
          ? props.initialSort
          : [
              {
                field: "id",
                dir: "asc",
              },
            ],

        filterMode: props.filterMode ? props.filterMode : "remote",
        pagination: props.pagination === false ? props.pagination : true,
        paginationMode: "remote",
        paginationButtonCount: 3,
        paginationCounter: "rows",
        paginationSize: props.perPage ? props.perPage : 10,
        paginationSizeSelector: [10, 25, 50, 100, 1000, 10000, 20000, 100000],
        dataSendParams: {
          page: "page",
          size: "per_page",
        },

        dataReceiveParams: {
          last_page: "last_page",
        },

        groupBy: props.groupBy,
        groupHeader: function (value, count, data, group) {
          // Customize the group header
          return `${value} - ${count} records`;
        },
        layout: props.layout ? props.layout : "fitColumns",
        responsiveLayout: props.responsiveLayout ? props.responsiveLayout : "",
        responsiveLayoutCollapseStartOpen: false,
        resizableColumnFit: true,
        layoutColumnsOnNewData: true,
        movableColumns: true,
        movableRows: props.movableRows ? props.movableRows : false,
        langs: {
          default: {
            pagination: {
              counter: {
                showing: "",
                of: "of",
                rows: "",
                pages: "",
              },
            },
          },
        },
        columns: props.columns,
        rowFormatter: props.rowFormatter,
      },
      []
    );

    // Add row moved event handler
    if (props.onRowMoved) {
      table.current.on("rowMoved", function(row) {
        const allRows = table.current.getRows();
        const newOrder = allRows.map((row, index) => ({
          id: row.getData().id,
          order: index + 1
        }));
        props.onRowMoved(newOrder);
      });
    }

    table.current.on("cellEdited", function (cell) {
      props.onCellEdit(cell.getRow().getData());
    });

    table.current.on(
      "rowSelectionChanged",
      function (data, rows, selected, deselected) {
        props.onDataSelection(data);
      }
    );

    if (props.rowClick) {
      table.current.on("rowClick", function (e, row) {
        props.rowClick(row.getData());
      });
    }

    if (props.cellClick) {
      table.current.on("cellClick", function (e, cell) {
        props.cellClick(cell);
      });
    }
  }, [props.tableData]);

  const addNewRow = () => {
    table.current.addRow({}, true);
  };

  const downloadCsvData = () => {
    let filterString = "";

    table.current.getFilters(true).forEach((filter) => {
      filterString += `+${filter.field}-${filter.value}`;
    });

    table.current.download(
      "csv",
      `${props.module ? props.module : "data"}${filterString}.csv`,
      { delimiter: "," }
    );
  };

  const downloadPdfData = () => {
    let filterString = "";

    table.current.getFilters(true).forEach((filter) => {
      filterString += `+${filter.field}-${filter.value}`;
    });

    table.current.download(
      "pdf",
      `${props.module ? props.module : "data"}${filterString}.pdf`,
      {
        orientation: props?.downloadOrientaion || "portrait",
        title: props?.title || "Booking Status",
      }
    );
  };

  const downloadXLSXData = () => {
    let filterString = "";

    table.current.getFilters(true).forEach((filter) => {
      filterString += `+${filter.field}-${filter.value}`;
    });

    table.current.download(
      "xlsx",
      `${props.module ? props.module : "data"}${filterString}.xlsx`,
      { sheetName: "MyData" }
    );
  };

  return (
    <div className="table-wrapper flex h-full w-[calc(100vw-3rem)] flex-col overflow-hidden lg:w-full">
      <div className="flex flex-wrap items-center justify-between">
        {props.search && (
          <div className="my-2 mb-2 flex items-center lg:my-4">
            <label htmlFor="" className="mr-1">
              Search:
            </label>
            <input
              type="text"
              name=""
              id=""
              className="field"
              onChange={handleSearch}
            />
          </div>
        )}

        {props.addRowBtn && (
          <div className="mb-2 flex items-center justify-end gap-2 text-jw-green">
            <button
              onClick={addNewRow}
              className="flex items-center gap-1 rounded-md border px-4 py-1"
              id="download-csv"
            >
              <FaPlusSquare /> {props.addRowBtn}
            </button>
          </div>
        )}

        {props.downloadAble && (
          <div className="flex grow justify-end">
            <div className="group relative mb-2 flex  items-center justify-end gap-2 ">
              <button className="rounded-md border border-jw-green bg-white px-4 py-1 text-jw-green">
                <FaDownload className="inline-block" /> Download
              </button>
              <div className="invisible absolute top-full right-0 z-10 rounded-lg bg-white text-right text-jw-green shadow-lg group-hover:visible">
                {(!props.downloadAbleFormats ||
                  props.downloadAbleFormats.includes("csv")) && (
                  <button
                    onClick={() => downloadCsvData()}
                    className="px-4 py-2"
                    id="download-csv"
                  >
                    <FaFileCsv className="inline-block text-sm" /> CSV
                  </button>
                )}
                {(!props.downloadAbleFormats ||
                  props.downloadAbleFormats.includes("xlsx")) && (
                  <button
                    onClick={() => downloadXLSXData()}
                    className="px-4 py-2"
                    id="download-xlsx"
                  >
                    <FaFileExcel className="inline-block text-sm" /> XLSX
                  </button>
                )}
                {(!props.downloadAbleFormats ||
                  props.downloadAbleFormats.includes("pdf")) && (
                  <button
                    onClick={() => downloadPdfData()}
                    className="px-4 py-2"
                    id="download-pdf"
                  >
                    <FaFilePdf className="inline-block text-sm" /> PDF
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className={`w-full grow overflow-auto ${
          props.height
            ? ""
            : props.downloadAble
            ? "h-[calc(100vh-15.75rem)]"
            : "h-[calc(100vh-13.75rem)]"
        } `}
        id="table-wrapper"
        ref={tableWrapperRef}
      >
        <div
          ref={tableRef}
          id="data-table"
          className={`w-full lg:w-full ${
            props.height ? `h-${props.height}` : "h-full"
          }`}
        ></div>
      </div>
    </div>
  );
});

export default memo(DataTable);
