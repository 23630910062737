import React, { useEffect, useRef } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import DataTable from "../components/common/DataTable";
import { getAdminUrl, getUploadsUrl } from "../../ServiceConfig";
import ComponentHeader from "../components/common/ComponentHeader";
import H2 from "../../components/headings/H2";
import { getReadableDate } from "../../helpers/Helper";
import SelectSearch from "../components/inputs/SelectSearch";
import {
  getPackageBookingDates,
  getUpcomingBookingsPackages,
} from "../services/BookingServices";
import { useState } from "react";
import { getPackage } from "../services/PackageService";
import moment from "moment";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import OutsideClick from "../../hooks/OutsideClick";
import {
  FaCheckSquare,
  FaCog,
  FaDownload,
  FaFileCsv,
  FaFileDownload,
  FaFileExcel,
  FaFilePdf,
  FaTimes,
} from "react-icons/fa";

function Participants() {
  const [searchParams, setSearchParams] = useSearchParams();

  const package_name = decodeURIComponent(searchParams.get("package_name"));
  const package_id = searchParams.get("package_id");
  const event_date = searchParams.get("event_date");
  const booking_type = searchParams.get("booking_type") ?? "";
  const [packageName, setPackageName] = useState("");
  const [columnVisibilityOpen, setColumnVisibilityOpen] = useState(false);
  const [downloadOptionOpen, setDownloadOptionOpen] = useState(false);
  const [renderedColumns, setRenderedColumns] = useState();

  useEffect(() => {
    if (package_id != null) {
      getPackage(package_id).then((res) => {
        console.log(res.data.data.title);
        setPackageName(res.data?.data?.title);
      });
    }
  }, []);

  const participantStatusTable = useRef();
  const [tableInstance, setTableInstance] = useState(null);

  useEffect(() => {
    if (participantStatusTable.current) {
      setTableInstance(participantStatusTable.current);
    }
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const packageType = location?.state?.packageType;

  const columns = [
    // {
    //   formatter: "responsiveCollapse",
    //   width: 30,
    //   minWidth: 30,
    //   responsive: 0,
    //   hozAlign: "center",
    //   resizable: false,
    //   headerSort: false,
    // },
    {
      title: "Sl no",

      minWidth: 40,
      formatter: "rownum",
    },
    {
      title: "Converted By",
      field: "converted_by",
      responsive: 0,
      resizable: true,
      vertAlign: "middle",
      minWidth: 150,
      download: true,
    },

    {
      title: "Name",
      field: "first_name",
      frozen: true,
      minWidth: 150,
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      formatter: function (cell, formatterParams, onRendered) {
        // return `${cell.getRow().getData().first_name} ${
        //   cell.getRow().getData().last_name
        //     ? cell.getRow().getData().last_name
        //     : ""
        // }`;
        return `<a href="/bookings/${
          cell.getRow().getData().booking_id
        }" class="underline text-blue-600">${
          cell.getRow().getData().first_name
        } ${
          cell.getRow().getData().last_name
            ? cell.getRow().getData().last_name
            : ""
        }</a>`;
      },
      resizable: true,
      download: true,
    },
    {
      title: "Booking Id",
      minWidth: 200,
      field: "booking_id",
      resizable: true,
      vertAlign: "middle",
      download: false,
      visible: false,
    },
    {
      title: "Email",
      minWidth: 200,
      field: "email",
      resizable: true,
      vertAlign: "middle",
      download: false,
    },
    {
      title: "Phone",
      minWidth: 150,
      field: "phone",
      resizable: true,
      vertAlign: "middle",
      download: true,
    },
    {
      title: "Whatsapp",
      field: "whatsapp",
      minWidth: 150,
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      download: true,
    },
    {
      title: "Gender",
      field: "gender",
      minWidth: 100,
      resizable: true,
      vertAlign: "middle",
      download: true,
    },
    {
      title: "Age",
      field: "age",
      resizable: true,
      minWidth: 100,
      vertAlign: "middle",
      download: true,
    },
    {
      title: "Travel Mode",
      field: "travel_mode",
      minWidth: 120,
      resizable: true,
      vertAlign: "middle",
      download: false,
      // formatter: function (cell, formatterParams, onRendered) {
      //   return travelModes?.filter(
      //     (tm) => tm.id === cell.getRow().getData().travel_mode_id
      //   )[0]?.name;
      // },
    },
    {
      title: "Rider",
      field: "rider",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
      download: false,
      // formatter: function (cell, formatterParams, onRendered) {
      //   return occupancies?.filter(
      //     (occ) => occ.id === cell.getRow().getData().occupancy_id
      //   )[0]?.name;
      // },
    },
    {
      title: "Occupancy",
      field: "occupancy",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
      download: false,
      // formatter: function (cell, formatterParams, onRendered) {
      //   return occupancies?.filter(
      //     (occ) => occ.id === cell.getRow().getData().occupancy_id
      //   )[0]?.name;
      // },
    },
    {
      title: "Comments",

      responsive: 0,
      resizable: true,
      vertAlign: "middle",
      minWidth: 150,
      download: true,
      formatter: function (cell, formatterParams, onRendered) {
        let comments = cell.getRow().getData().comments;
        let html = "<div>";
        if (
          cell.getRow().getData().verification_link_comments?.comment != null
        ) {
          html += `<p>Before Verification: ${
            cell.getRow().getData().verification_link_comments?.comment
          } </p>`;
        }
        comments.forEach((comment, i) => {
          html += `<p>${i + 1}.${comment?.comment} </p>`;
        });
        html += "</div>";
        return html;
      },
      download: false,
    },
    {
      title: "Eme. Contact",
      field: "emergency_contact",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
      download: true,
    },
    {
      title: "Eme. Relation",
      field: "emergency_contact_relation",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
      download: false,
    },
    {
      title: "Eme. Name",
      field: "emergency_contact_name",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
      download: false,
    },
    {
      title: "Coming From",
      field: "coming_from",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
      download: false,
    },
    {
      title: "ID Type",
      field: "id_proof",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
      download: false,
    },
    {
      title: "ID Number",
      field: "id_number",
      minWidth: 150,
      resizable: true,
      vertAlign: "middle",
      download: false,
    },
    {
      title: "Id Proof",
      field: "id_attachment",
      resizable: true,
      vertAlign: "middle",
      minWidth: "150",
      formatter: function (cell, formatterParams, onRendered) {
        if (
          cell.getRow().getData().id_attachment !== "" &&
          cell.getRow().getData().id_attachment !== null
        ) {
          if (
            cell.getRow().getData().id_attachment.split(".").pop() ===
            "pdf"
          ) {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_id_attachment/${
              cell.getRow().getData().id_attachment
            }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
          } else {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_id_attachment/${
              cell.getRow().getData().id_attachment
            }" ><img src="${getUploadsUrl()}/bookings/participant_id_attachment/${
              cell.getRow().getData().id_attachment
            }"/></a>`;
          }
        } else {
          return "No Attachment";
        }
      },
    },
    {
      title: "PAN Card",
      field: "pan_card",
      resizable: true,
      vertAlign: "middle",
      minWidth: "150",
      formatter: function (cell, formatterParams, onRendered) {
        if (
          cell.getRow().getData().pan_card !== "" &&
          cell.getRow().getData().pan_card !== null
        ) {
          if (
            cell.getRow().getData().pan_card.split(".").pop() ===
            "pdf"
          ) {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_pan/${
              cell.getRow().getData().pan_card
            }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
          } else {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_pan/${
              cell.getRow().getData().pan_card
            }" ><img src="${getUploadsUrl()}/bookings/participant_pan/${
              cell.getRow().getData().pan_card
            }"/></a>`;
          }
        } else {
          return "No Attachment";
        }
      },
    },
    {
      title: "Visa",
      field: "visa",
      resizable: true,
      vertAlign: "middle",
      minWidth: "150",
      formatter: function (cell, formatterParams, onRendered) {
        if (
          cell.getRow().getData().visa !== "" &&
          cell.getRow().getData().visa !== null
        ) {
          if (
            cell.getRow().getData().visa.split(".").pop() ===
            "pdf"
          ) {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_visa/${
              cell.getRow().getData().visa
            }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
          } else {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_visa/${
              cell.getRow().getData().visa
            }" ><img src="${getUploadsUrl()}/bookings/participant_visa/${
              cell.getRow().getData().visa
            }"/></a>`;
          }
        } else {
          return "No Attachment";
        }
      },
    },
    {
      title: "Air Ticket",
      field: "air_ticket",
      resizable: true,
      vertAlign: "middle",
      minWidth: "150",
      formatter: function (cell, formatterParams, onRendered) {
        if (
          cell.getRow().getData().air_ticket !== "" &&
          cell.getRow().getData().air_ticket !== null
        ) {
          if (
            cell.getRow().getData().air_ticket.split(".").pop() ===
            "pdf"
          ) {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_air_ticket/${
              cell.getRow().getData().air_ticket
            }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
          } else {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_air_ticket/${
              cell.getRow().getData().air_ticket
            }" ><img src="${getUploadsUrl()}/bookings/participant_air_ticket/${
              cell.getRow().getData().air_ticket
            }"/></a>`;
          }
        } else {
          return "No Attachment";
        }
      },
    },
    {
      title: "Medical Certificate",
      field: "medical_certificate",
      resizable: true,
      minWidth: "150",
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (
          cell.getRow().getData().medical_certificate !== "" &&
          cell.getRow().getData().medical_certificate !== null
        ) {
          if (
            cell.getRow().getData().medical_certificate.split(".").pop() ===
            "pdf"
          ) {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_medical_certificate/${
              cell.getRow().getData().medical_certificate
            }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
          } else {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_medical_certificate/${
              cell.getRow().getData().medical_certificate
            }" ><img src="${getUploadsUrl()}/bookings/participant_medical_certificate/${
              cell.getRow().getData().medical_certificate
            }"/></a>`;
          }
        } else {
          return "No Attachment";
        }
      },
    },
    // {
    //   title: "Medical Certificate",
    //   field: "medical_certificate",
    //   resizable: true,
    //   vertAlign: "middle",
    //   minWidth: 100,
    //   formatter: function (cell, formatterParams, onRendered) {
    //     if (
    //       cell.getRow().getData().medical_certificate != "" &&
    //       cell.getRow().getData().medical_certificate != null
    //     ) {
    //       if (
    //         cell.getRow().getData().medical_certificate.split(".").pop() ==
    //         "pdf"
    //       ) {
    //         return `<a href="${getUploadsUrl()}/bookings/participant_medical_certificate/${
    //           cell.getRow().getData().medical_certificate
    //         }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
    //       } else {
    //         return `<a href="${getUploadsUrl()}/bookings/participant_medical_certificate/${
    //           cell.getRow().getData().medical_certificate
    //         }" ><img src="${getUploadsUrl()}/bookings/participant_medical_certificate/${
    //           cell.getRow().getData().medical_certificate
    //         }"/></a>`;
    //       }
    //     } else {
    //       return "No Attachment";
    //     }
    //   },
    //   formatterParams: {
    //     height: "50px",
    //     width: "80px",
    //     // urlPrefix: `${getUploadsUrl()}/bookings/participant_id_attachment/`,
    //   },
    // },
    // {
    //   title: "isa",
    //   field: "id_attachment",
    //   resizable: true,
    //   vertAlign: "middle",
    //   minWidth: 100,
    //   formatter: function (cell, formatterParams, onRendered) {
    //     if (
    //       cell.getRow().getData().id_attachment != "" &&
    //       cell.getRow().getData().id_attachment != null
    //     ) {
    //       if (cell.getRow().getData().id_attachment.split(".").pop() == "pdf") {
    //         return `<a href="${getUploadsUrl()}/bookings/participant_id_attachment/${
    //           cell.getRow().getData().id_attachment
    //         }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
    //       } else {
    //         return `<a href="${getUploadsUrl()}/bookings/participant_id_attachment/${
    //           cell.getRow().getData().id_attachment
    //         }" ><img src="${getUploadsUrl()}/bookings/participant_id_attachment/${
    //           cell.getRow().getData().id_attachment
    //         }"/></a>`;
    //       }
    //     } else {
    //       return "No Attachment";
    //     }
    //   },
    //   formatterParams: {
    //     height: "50px",
    //     width: "80px",
    //     // urlPrefix: `${getUploadsUrl()}/bookings/participant_id_attachment/`,
    //   },
    // },
    // {
    //   title: "Id Attachment",
    //   field: "id_attachment",
    //   resizable: true,
    //   vertAlign: "middle",
    //   minWidth: 100,
    //   formatter: function (cell, formatterParams, onRendered) {
    //     if (
    //       cell.getRow().getData().id_attachment != "" &&
    //       cell.getRow().getData().id_attachment != null
    //     ) {
    //       if (cell.getRow().getData().id_attachment.split(".").pop() == "pdf") {
    //         return `<a href="${getUploadsUrl()}/bookings/participant_id_attachment/${
    //           cell.getRow().getData().id_attachment
    //         }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
    //       } else {
    //         return `<a href="${getUploadsUrl()}/bookings/participant_id_attachment/${
    //           cell.getRow().getData().id_attachment
    //         }" ><img src="${getUploadsUrl()}/bookings/participant_id_attachment/${
    //           cell.getRow().getData().id_attachment
    //         }"/></a>`;
    //       }
    //     } else {
    //       return "No Attachment";
    //     }
    //   },
    //   formatterParams: {
    //     height: "50px",
    //     width: "80px",
    //     // urlPrefix: `${getUploadsUrl()}/bookings/participant_id_attachment/`,
    //   },
    // },
    // {
    //   title: "Id Attachment",
    //   field: "id_attachment",
    //   resizable: true,
    //   vertAlign: "middle",
    //   minWidth: 100,
    //   formatter: function (cell, formatterParams, onRendered) {
    //     if (
    //       cell.getRow().getData().id_attachment != "" &&
    //       cell.getRow().getData().id_attachment != null
    //     ) {
    //       if (cell.getRow().getData().id_attachment.split(".").pop() == "pdf") {
    //         return `<a href="${getUploadsUrl()}/bookings/participant_id_attachment/${
    //           cell.getRow().getData().id_attachment
    //         }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
    //       } else {
    //         return `<a href="${getUploadsUrl()}/bookings/participant_id_attachment/${
    //           cell.getRow().getData().id_attachment
    //         }" ><img src="${getUploadsUrl()}/bookings/participant_id_attachment/${
    //           cell.getRow().getData().id_attachment
    //         }"/></a>`;
    //       }
    //     } else {
    //       return "No Attachment";
    //     }
    //   },
    //   formatterParams: {
    //     height: "50px",
    //     width: "80px",
    //     // urlPrefix: `${getUploadsUrl()}/bookings/participant_id_attachment/`,
    //   },
    // },
    {
      title: "Remaining Amount",
      field: "remaining_amount",
      minWidth: 150,
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      frozen: true,
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getRow().getData().remaining_amount > 0) {
          cell.getElement().style.backgroundColor = "#FB5D5D"; // set background color to red
          cell.getElement().style.color = "#ffffff"; // set text color to white
          return cell.getRow().getData().remaining_amount;
        } else {
          // cell.getElement().style.backgroundColor = "#"; // set background color to red
          // cell.getElement().style.color = "#000000"; // set text color to white
          return cell.getRow().getData().remaining_amount;
        }
      },
      download: true,
    },
  ];

  // useEffect(() => {
  //   getUpcomingBookingsPackages().then((res) => {
  //     let pkgs = [];
  //     res.data.data.forEach((data) => {
  //       pkgs.push({
  //         label: data.package_title,
  //         value: parseInt(data.package_id),
  //       });
  //     });
  //     setPackages(pkgs);
  //   });
  // }, []);

  // useEffect(() => {
  //   getPackageBookingDates(selectedPackage).then((res) => {
  //     let dates = [];

  //     res.data.data.forEach((data) => {
  //       dates.push({
  //         value: data.event_date,
  //         label: getReadableDate(data.event_date),
  //       });
  //     });

  //     setDates(dates);
  //   });
  // }, [selectedPackage]);

  const rowClick = (data) => {
    navigate(`/bookings/${data.booking_id}`);
  };

  const downloadZip = async () => {
    if (!tableInstance) {
      console.error("Table instance is not initialized");
      return;
    }

    const zip = new JSZip();
    const folder = zip.folder(
      `Attachment - Participants Detail For : ${
        packageName != null && packageName != ""
          ? packageName
          : package_name.replaceAll("_", " ")
      } - ${moment(event_date).format("MMMM Do, YYYY")}`
    );

    const data = tableInstance.getData();

    for (let i = 0; i < data.length; i++) {
      // console.log(i);
      const rowData = data[i];
      const name = `${rowData.booking_id} - ${rowData.first_name || ""} ${
        rowData.last_name || ""
      }`;
      const imagePath = `${getUploadsUrl()}/bookings/participant_id_attachment/${
        rowData.id_attachment
      }`;
      if (rowData.id_attachment) {
        // Fetch image as blob
        const response = await fetch(imagePath);
        const blob = await response.blob();

        // Add image to zip
        folder.file(`${name}/${imagePath.split("/").pop()}`, blob);
      }

      // Add text data to zip
      // folder.file(`${name}/data.txt`, JSON.stringify(rowData, null, 2));
    }

    // Generate the zip file and prompt download
    zip.generateAsync({ type: "blob" }).then(function (content) {
      saveAs(
        content,
        `Attachment - Participants Detail For : ${
          packageName != null && packageName != ""
            ? packageName
            : package_name.replaceAll("_", " ")
        } - ${moment(event_date).format("MMMM Do, YYYY")}.zip`
      );
    });
  };

  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <div className="flex w-full items-center justify-between">
          <H2>
            Participants Detail For :{" "}
            {packageName != null && packageName != ""
              ? packageName
              : package_name.replaceAll("_", " ")}{" "}
            - {moment(event_date).format("MMMM Do, YYYY")}
          </H2>
          {/* <div className="inline-flex items-center justify-end gap-4">
            <SelectSearch
              options={packages}
              value={parseInt(selectedPackage)}
              onChange={(value) => {
                setSelectedPackage(value);
                setSelectedDate();
              }}
            />
            <SelectSearch
              options={dates}
              value={selectedDate}
              onChange={(value) => setSelectedDate(value)}
            />
          </div> */}
        </div>
      </ComponentHeader>
      <div className="h-auto py-2">
        <div className="relative inline-block">
          <OutsideClick onClick={() => setColumnVisibilityOpen(false)}>
            <button
              onClick={() => {
                setColumnVisibilityOpen(!columnVisibilityOpen);
                setRenderedColumns(participantStatusTable.current.getColumns());
              }}
              className={`text-md mr-2 flex  items-center gap-1 rounded-md border bg-white p-8 py-2 font-semibold tracking-wide `}
            >
              <FaCog /> Column Visibility
            </button>

            <div
              className={`${
                columnVisibilityOpen ? "" : "hidden"
              } absolute left-0 top-full  z-40 mt-2 w-full rounded-md border bg-white py-0`}
            >
              <ul className="flex max-h-[300px] flex-col overflow-auto">
                {renderedColumns &&
                  renderedColumns.map((column, key) => (
                    <div key={key}>
                      {column._column.definition.title && (
                        <label htmlFor={`id${key}`} className={`m-0 !h-0 p-0`}>
                          <li
                            className={`${
                              column.isVisible()
                                ? "bg-white text-gray-900"
                                : "bg-gray-200 text-gray-900"
                            } text-md cursor-pointer items-center border-y border-slate-100 py-2 px-4  text-justify align-middle font-bold`}
                          >
                            <div className="flex items-center justify-between">
                              <p className="inline-block">
                                {column._column.definition.title}
                              </p>
                              <input
                                id={`id${key}`}
                                type="checkbox"
                                value="1"
                                checked={column.isVisible()}
                                onChange={(e) => {
                                  column.toggle();
                                  setRenderedColumns(
                                    participantStatusTable.current.getColumns()
                                  );
                                }}
                                className="hidden"
                              />
                              <div className="right-1 inline-block">
                                {column.isVisible() ? (
                                  <div className="text-green-700">
                                    <FaCheckSquare />
                                  </div>
                                ) : (
                                  <div className="text-red-700">
                                    <FaTimes />
                                  </div>
                                )}
                              </div>
                            </div>
                          </li>
                        </label>
                      )}
                    </div>
                  ))}
              </ul>
            </div>
          </OutsideClick>
        </div>
        <div className="relative inline-block">
          <OutsideClick onClick={() => setDownloadOptionOpen(false)}>
            <button
              onClick={() => {
                setDownloadOptionOpen(!downloadOptionOpen);
              }}
              className={`text-md mr-2 flex  items-center gap-1 rounded-md border bg-white p-8 py-2 font-semibold tracking-wide `}
            >
              <FaDownload />
              Download
            </button>

            <div
              className={`${
                downloadOptionOpen ? "" : "hidden"
              } absolute left-0 top-full  z-40 mt-2 w-full rounded-md border bg-white py-0`}
            >
              <ul className="flex max-h-[300px] flex-col overflow-auto">
                <div key="csv">
                  <li
                    onClick={() =>
                      participantStatusTable.current.download(
                        "csv",
                        `Attachment - Participants Detail For : ${
                          packageName != null && packageName != ""
                            ? packageName
                            : package_name.replaceAll("_", " ")
                        } - ${moment(event_date).format("MMMM Do, YYYY")}.csv`,
                        {
                          delimiter: ",",
                        }
                      )
                    }
                    className={`text-gray-900text-md cursor-pointer items-center border-y border-slate-100 bg-white py-2 px-4  text-justify align-middle font-bold`}
                  >
                    <div className="flex items-center justify-between">
                      <p className="inline-block">CSV</p>

                      <div className="right-1 inline-block">
                        <div className="text-green-700">
                          <FaFileCsv />
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
                <div key="xlsx">
                  <li
                    onClick={() =>
                      participantStatusTable.current.download(
                        "xlsx",
                        `Attachment - Participants Detail For : ${
                          packageName != null && packageName != ""
                            ? packageName
                            : package_name.replaceAll("_", " ")
                        } - ${moment(event_date).format("MMMM Do, YYYY")}.xlsx`,
                        {
                          sheetName: "sheet1",
                        }
                      )
                    }
                    className={`text-gray-900text-md cursor-pointer items-center border-y border-slate-100 bg-white py-2 px-4  text-justify align-middle font-bold`}
                  >
                    <div className="flex items-center justify-between">
                      <p className="inline-block">XLSX</p>

                      <div className="right-1 inline-block">
                        <div className="text-green-700">
                          <FaFileExcel />
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
                <div key="pdf">
                  <li
                    onClick={() =>
                      participantStatusTable.current.download(
                        "pdf",
                        `Participants Detail For : ${
                          packageName != null && packageName != ""
                            ? packageName
                            : package_name.replaceAll("_", " ")
                        } - ${moment(event_date).format("MMMM Do, YYYY")}.pdf`,
                        {
                          orientation: "landscape",
                          title: `Participants Detail For : ${
                            packageName != null && packageName != ""
                              ? packageName
                              : package_name.replaceAll("_", " ")
                          } - ${moment(event_date).format("MMMM Do, YYYY")}`,
                        }
                      )
                    }
                    className={`text-gray-900text-md cursor-pointer items-center border-y border-slate-100 bg-white py-2 px-4  text-justify align-middle font-bold`}
                  >
                    <div className="flex items-center justify-between">
                      <p className="inline-block">PDF</p>

                      <div className="right-1 inline-block">
                        <div className="text-red-500">
                          <FaFilePdf />
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
                <div key="attachment">
                  <li
                    onClick={downloadZip}
                    className={`text-gray-900text-md cursor-pointer items-center border-y border-slate-100 bg-white py-2 px-4  text-justify align-middle font-bold`}
                  >
                    <div className="flex items-center justify-between">
                      <p className="inline-block">Attachment</p>

                      <div className="right-1 inline-block">
                        <div className="text-amber-700">
                          <FaFileDownload />
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
          </OutsideClick>
        </div>
      </div>
      <div className="h-auto">
        <DataTable
          ref={participantStatusTable}
          ajaxUrl={
            booking_type === "customized"
              ? `${getAdminUrl()}/viewallCustomizedParticipants?package_name=${encodeURIComponent(
                  package_name
                )}&event_date=${event_date}`
              : `${getAdminUrl()}/viewallParticipants?package_id=${package_id}&event_date=${event_date}`
          }
          columns={columns}
          search={false}
          // rowClick={rowClick}
          initialSort={[{ column: "id", dir: "desc" }]}
          height={false}
          responsiveLayout={false}
          groupBy="booking_id"
          // downloadAble={true}
          // downloadOrientaion="landscape"
          // title={`Participants Detail : ${
          //   package_name || packageName
          // }  - ${event_date}`}
          // module={`Participants Detail : ${
          //   package_name || packageName
          // }  - ${event_date}`}
        />
      </div>
    </div>
  );
}

export default Participants;
