import { useState } from "react";
import { useEffect } from "react";
import { FaExternalLinkAlt, FaRupeeSign } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { formatNumberToTwoDigit, getReadableDate } from "../../helpers/Helper";
import { getUploadsUrl } from "../../ServiceConfig";
import {
  getBookingById,
  getVerificationLinkById,
  rejectVerificationLink,
  verifyVerificationLink,
} from "../services/BookingServices";
import {
  getAllEmployee,
  getAllEmployeesOption,
} from "../services/EmployeeServices";
import { getAllOccupancies } from "../services/PackageService";
import { getAllRidersOption } from "../services/RiderServices";
import { getAllStateByCountry } from "../services/StateServices";
import { getAllTravelModesOption } from "../services/TravelModeServices";
import UpdateParticipants from "./UpdateParticipants";

import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import UpdatePaymentReciept from "./UpdatePaymentReciept";
import DataTable from "../components/common/DataTable";
import useAdminAuth from "../hooks/useAdminAuth";
import { toast } from "react-toastify";

Modal.setAppElement("#modal");

function ViewVerificationLink() {
  const bookingId = useParams().id;

  const navigate = useNavigate();

  const [bookingData, setBookingData] = useState();
  const [states, setStates] = useState();
  const [travelModes, setTravelModes] = useState();
  const [riders, setRiders] = useState();
  const [occupancies, setOccupancies] = useState();
  const [employees, setEmployees] = useState();
  const [participantModalIsOpen, setParticipantModalIsOpen] = useState(false);
  const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);

  const [recieptToUpdate, setRecieptToUpdate] = useState();
  const [activeParticipantData, setActiveParticipantData] = useState();
  const [particpantIdModalIsOpen, setParticpantIdModalIsOpen] = useState(false);
  const { auth, setAuth } = useAdminAuth();

  const getVerficationLinkData = () => {
    getVerificationLinkById(bookingId).then((res) => {
      setBookingData(res.data.data);
    });
  };

  useEffect(() => {
    getVerficationLinkData();
  }, [bookingId]);

  useEffect(() => {
    getAllTravelModesOption().then((res) => {
      setTravelModes(res.data.data);
    });

    getAllRidersOption().then((res) => {
      setRiders(res.data.data);
    });

    getAllOccupancies().then((res) => {
      setOccupancies(res.data.data);
    });

    getAllEmployeesOption().then((res) => {
      setEmployees(res.data.data.data);
    });
  }, []);

  useEffect(() => {
    if (!bookingData?.country_id) return;
    getAllStateByCountry(bookingData?.country_id).then((res) => {
      setStates(res.data.data);
    });
  }, [bookingData]);

  useEffect(() => {
    recieptToUpdate && setPaymentModalIsOpen(true);
  }, [recieptToUpdate]);

  const getEmployeeName = (empId) => {
    let name = "";
    let emp = employees?.filter((emp) => emp.id === empId);
    name = `${emp?.[0]?.first_name} ${emp?.[0]?.last_name}`;
    return name;
  };

  const getRiderName = (riderId) => {
    let riderName = riders?.filter((rider) => rider.id === riderId)[0]?.name;

    if (riderName) {
      return riderName;
    } else {
      return "N/A";
    }
  };

  const getUpdatedData = () => {
    getBookingById(bookingId).then((res) => {
      setBookingData(res.data.data);
    });
  };

  useEffect(() => {
    activeParticipantData && setParticpantIdModalIsOpen(true);
  }, [activeParticipantData]);

  const verifyPaymentAndCreateReceipt = (e) => {
    e.target.disabled = true;
    verifyVerificationLink(bookingId)
      .then((res) => {
        navigate(`/bookings/${res.data.data.id}`);
      })
      .catch((err) => {
        e.target.disabled = false;
        console.error(err);
        toast.error(err.response.data.message);
      });
  };

  const rejectAndSendForReview = () => {
    rejectVerificationLink(bookingId).then((res) => {
      getVerficationLinkData();
    });
  };

  const getVerificationStatus = (code) => {
    if (code === 0) return "User Verification Awaited";
    if (code === 1) return "User Verified";
    if (code === 2) return "Rejected";
    if (code === 3) return "Verified";
  };

  const viewBooking = () => {
    if (!bookingData?.booking_id) return;
    navigate(`/bookings/${bookingData?.booking_id}`);
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Membership",
      field: "package_name",
      minWidth: "130",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Name",
      minWidth: "130",
      field: "event_date",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return `${cell.getRow().getData().first_name} ${
          cell.getRow().getData().last_name
        }`;
      },
    },
    {
      title: "Email",
      minWidth: "130",
      field: "email",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Phone",
      field: "phone",
      minWidth: "100",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Whatsapp",
      field: "whatsapp",
      resizable: true,
      vertAlign: "middle",
      minWidth: "100",
    },
    {
      title: "Age",
      field: "age",
      resizable: true,
      headerSort: false,
      vertAlign: "middle",
      hozAlign: "center",
      width: "60",
    },
    {
      title: "Gender",
      field: "gender",
      resizable: true,
      headerSort: true,
      vertAlign: "middle",
      minWidth: "100",
      hozAlign: "center",
    },
    {
      title: "Travel Mode",
      field: "travel_mode_id",
      minWidth: "140",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        return travelModes?.filter(
          (tm) => tm.id === cell.getRow().getData().travel_mode_id
        )[0]?.name;
      },
    },
    {
      title: "Occupancy",
      field: "status",
      resizable: true,
      vertAlign: "middle",
      minWidth: "140",
      formatter: function (cell, formatterParams, onRendered) {
        return occupancies?.filter(
          (occ) => occ.id === cell.getRow().getData().occupancy_id
        )[0]?.name;
      },
    },
    {
      title: "Emg. Contact",
      field: "emergency_contact",
      resizable: true,
      vertAlign: "middle",
      minWidth: "140",
    },
    {
      title: "Relation",
      field: "emergency_contact_relation",
      resizable: true,
      vertAlign: "middle",
      minWidth: "100",
    },
    {
      title: "Id Proof",
      field: "id_attachment",
      resizable: true,
      vertAlign: "middle",
      minWidth: "150",
      formatter: function (cell, formatterParams, onRendered) {
        if (
          cell.getRow().getData().id_attachment !== "" &&
          cell.getRow().getData().id_attachment !== null
        ) {
          if (
            cell.getRow().getData().id_attachment.split(".").pop() ===
            "pdf"
          ) {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_id_attachment/${
              cell.getRow().getData().id_attachment
            }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
          } else {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_id_attachment/${
              cell.getRow().getData().id_attachment
            }" ><img src="${getUploadsUrl()}/bookings/participant_id_attachment/${
              cell.getRow().getData().id_attachment
            }"/></a>`;
          }
        } else {
          return "No Attachment";
        }
      },
    },
    {
      title: "PAN Card",
      field: "pan_card",
      resizable: true,
      vertAlign: "middle",
      minWidth: "150",
      formatter: function (cell, formatterParams, onRendered) {
        if (
          cell.getRow().getData().pan_card !== "" &&
          cell.getRow().getData().pan_card !== null
        ) {
          if (
            cell.getRow().getData().pan_card.split(".").pop() ===
            "pdf"
          ) {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_pan/${
              cell.getRow().getData().pan_card
            }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
          } else {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_pan/${
              cell.getRow().getData().pan_card
            }" ><img src="${getUploadsUrl()}/bookings/participant_pan/${
              cell.getRow().getData().pan_card
            }"/></a>`;
          }
        } else {
          return "No Attachment";
        }
      },
    },
    {
      title: "Visa",
      field: "visa",
      resizable: true,
      vertAlign: "middle",
      minWidth: "150",
      formatter: function (cell, formatterParams, onRendered) {
        if (
          cell.getRow().getData().visa !== "" &&
          cell.getRow().getData().visa !== null
        ) {
          if (
            cell.getRow().getData().visa.split(".").pop() ===
            "pdf"
          ) {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_visa/${
              cell.getRow().getData().visa
            }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
          } else {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_visa/${
              cell.getRow().getData().visa
            }" ><img src="${getUploadsUrl()}/bookings/participant_visa/${
              cell.getRow().getData().visa
            }"/></a>`;
          }
        } else {
          return "No Attachment";
        }
      },
    },
    {
      title: "Air Ticket",
      field: "air_ticket",
      resizable: true,
      vertAlign: "middle",
      minWidth: "150",
      formatter: function (cell, formatterParams, onRendered) {
        if (
          cell.getRow().getData().air_ticket !== "" &&
          cell.getRow().getData().air_ticket !== null
        ) {
          if (
            cell.getRow().getData().air_ticket.split(".").pop() ===
            "pdf"
          ) {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_air_ticket/${
              cell.getRow().getData().air_ticket
            }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
          } else {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_air_ticket/${
              cell.getRow().getData().air_ticket
            }" ><img src="${getUploadsUrl()}/bookings/participant_air_ticket/${
              cell.getRow().getData().air_ticket
            }"/></a>`;
          }
        } else {
          return "No Attachment";
        }
      },
    },
    {
      title: "Medical Certificate",
      field: "medical_certificate",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (
          cell.getRow().getData().medical_certificate !== "" &&
          cell.getRow().getData().medical_certificate !== null
        ) {
          if (
            cell.getRow().getData().medical_certificate.split(".").pop() ===
            "pdf"
          ) {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_medical_certificate/${
              cell.getRow().getData().medical_certificate
            }" ><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/PDF_file_icon.svg/1200px-PDF_file_icon.svg.png"/></a>`;
          } else {
            return `<a target="_blank" href="${getUploadsUrl()}/bookings/participant_medical_certificate/${
              cell.getRow().getData().medical_certificate
            }" ><img src="${getUploadsUrl()}/bookings/participant_medical_certificate/${
              cell.getRow().getData().medical_certificate
            }"/></a>`;
          }
        } else {
          return "No Attachment";
        }
      },
    },
    // {
    //   title: "Covid Certificate",
    //   field: "covid_certificate",
    //   resizable: true,
    //   vertAlign: "middle",
    //   formatter: "image",
    //   formatterParams: {
    //     height: "50px",
    //     width: "80px",
    //     urlPrefix: `${getUploadsUrl()}/bookings/participants/covid-certifcates/`,
    //   },
    // },
    // {
    //   title: "Resend Declaration",
    //   formatter: actionIcons,
    //   headerSort: false,
    //   width: 80,
    //   responsive: 0,
    //   hozAlign: "center",
    //   vertAlign: "middle",
    // },
  ];

  return (
    <div className="p-4">
      <div className="my-4 flex items-center justify-between">
        <h2 className="text-xl font-bold">
          Verification Link : #{bookingData?.id}
        </h2>
        {/* <div>
          <Link
            to={`/bookings/verification-links/edit/${bookingData?.id}`}
            className="btn-primary"
          >
            Edit Verification Link
          </Link>
        </div> */}
      </div>
      <div className="rounded-lg border-2 p-4">
        <h2 className="border-b-2 pb-4 text-xl font-bold">Billing Info</h2>
        <div className="my-4 flex flex-wrap items-center">
          <div className="my-2 basis-1/3">
            <b>Name : </b> {bookingData?.first_name} {bookingData?.last_name}
          </div>
          <div className="my-2 basis-1/3">
            <b>Email : </b> {bookingData?.email}
          </div>
          <div className="my-2 basis-1/3">
            <b>Phone : </b> {bookingData?.phone}
          </div>
          <div className="my-2 basis-1/3">
            <b>Company : </b> {bookingData?.firm_name}
          </div>
          <div className="my-2 basis-1/3">
            <b>GST Number : </b> {bookingData?.gst_number}
          </div>
          <div className="my-2 basis-1/3">
            <b>Address : </b> {bookingData?.firm_address}
          </div>
          <div className="my-2 basis-1/3">
            <b>State : </b>{" "}
            {
              states?.filter((state) => state.id === bookingData?.state_id)[0]
                ?.name
            }
          </div>
        </div>
      </div>
      <div className="my-4 rounded-lg border-2 bg-white p-4">
        <h2 className="border-b-2 pb-4 text-xl font-bold">Package Info</h2>
        <div className="my-4 flex flex-wrap items-center">
          <div
            className={
              bookingData?.is_customized
                ? "hidden"
                : "my-2 basis-full lg:basis-1/3"
            }
          >
            <b>Package Id: </b> {bookingData?.package_id}
          </div>
          <div className="my-2 grow basis-full lg:basis-2/3">
            <b>Package : </b> {bookingData?.package_name}
          </div>

          <div className="my-2 basis-full lg:basis-1/3">
            <b>Event Date : </b> {getReadableDate(bookingData?.event_date)}
          </div>
          <div className="my-2 basis-full lg:basis-1/3">
            <b>Pick Drop Location : </b> {bookingData?.starting_location}{" "}
            &#8594; {bookingData?.ending_location}
          </div>
          <div className="my-2 basis-full lg:basis-1/3">
            <b>Number of days : </b> {bookingData?.no_of_days}{" "}
          </div>
          <div className="my-2 basis-full">
            <b>Special Notes : </b>
            <div
              dangerouslySetInnerHTML={{
                __html: `${bookingData?.special_notes || ""}`,
              }}
            ></div>
          </div>
          {/* <div className="my-2 basis-full">
            <b>Custom Itinerary : </b>
          </div> */}
        </div>
      </div>
      <div className="my-4 rounded-lg border-2 bg-white p-4">
        <h2 className="text-xl font-bold">Cost Calculation</h2>
        <div className="mb-4 overflow-x-auto">
          <table className="w-full table-auto text-center	">
            <thead>
              <tr className="border-b bg-gray-200">
                <th className="py-2">Travel Mode</th>
                <th className="py-2">Rider</th>
                <th className="py-2">Occupancy</th>
                <th className="py-2">Price</th>
                <th className="py-2">Discount</th>
                <th className="py-2">Final</th>
                <th className="py-2">GST</th>
                <th className="py-2">With GST</th>
                <th className="py-2">Wraveler</th>
                <th className="py-2">Amount</th>
              </tr>
            </thead>
            <tbody>
              {bookingData?.cost?.map((booking, index) => {
                return (
                  <tr
                    key={booking.id}
                    className={`border-b ${
                      index % 2 === 0 ? "" : "bg-gray-50"
                    }`}
                  >
                    <td className="py-2">
                      {
                        travelModes?.filter(
                          (tm) => tm.id === parseInt(booking?.travel_mode?.id)
                        )[0]?.name
                      }
                    </td>
                    <td className="py-2">{getRiderName(booking?.rider?.id)}</td>
                    <td className="py-2">
                      {
                        occupancies?.filter(
                          (occ) => occ.id === parseInt(booking?.occupancy?.id)
                        )[0]?.name
                      }
                    </td>
                    <td className="py-2">{booking?.package_price}</td>
                    <td className="py-2">{booking?.package_discount}</td>
                    <td className="py-2">
                      {booking?.package_price_after_discount}
                    </td>
                    <td className="py-2">{booking?.package_gst}</td>
                    <td className="py-2">{booking?.package_with_gst}</td>
                    <td className="py-2">{booking?.pax}</td>
                    <td className="py-2">{booking?.amount}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {bookingData?.addons?.length > 0 && (
        <div className="my-4 rounded-lg border-2 bg-white p-4">
          <h2 className="text-xl font-bold">Addons</h2>
          <div className="mb-4">
            <table className="w-full text-center">
              <thead>
                <tr className="border-b bg-gray-200">
                  <th className="py-2">Title</th>
                  <th className="py-2">Description</th>
                  <th className="py-2">Unit Type</th>
                  <th className="py-2">Unit Price</th>
                  <th className="py-2">Unit GST</th>
                  <th className="py-2">Unit</th>
                  <th className="py-2">Amount</th>
                </tr>
              </thead>
              <tbody>
                {bookingData?.addons?.map((addon, index) => {
                  return (
                    <tr
                      key={addon.id}
                      className={`border-b ${
                        index % 2 === 0 ? "" : "bg-gray-50"
                      }`}
                    >
                      <td className="py-2">{addon?.title}</td>
                      <td className="py-2">{addon?.description}</td>
                      <td className="py-2">{addon?.unit_type}</td>
                      <td className="py-2">{addon?.unit_price}</td>
                      <td className="py-2">{addon?.unit_gst}</td>
                      <td className="py-2">{addon?.unit}</td>
                      <td className="py-2">{addon?.amount}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
      <div className="lg:flex-column flex flex-col items-end justify-between gap-4 pb-4">
        <div className="text-right">
          <table className="w-full">
            <tbody>
              <tr>
                <td className="font-semibold">Total Pax :</td>
                <td className="px-4 py-1 font-bold">
                  {formatNumberToTwoDigit(bookingData?.total_pax)}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Total Price :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_cost}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">
                  Total Discount{" "}
                  {bookingData?.is_coupon_applied
                    ? `(Coupon Code - ${bookingData?.coupon_code})`
                    : ""}{" "}
                  {bookingData?.is_membership_used
                    ? `(Membership Applied)`
                    : ""}{" "}
                  :{" "}
                </td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_discount}
                </td>
              </tr>
              <tr className="border-t font-semibold text-sky-600">
                <td>Total Cost After Discount :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_cost_after_discount}
                </td>
              </tr>
              <tr>
                <td className="font-semibold">Total GST :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_gst}
                </td>
              </tr>
              <tr className="border-t font-semibold text-sky-600">
                <td className="font-semibold">Total Amount after GST :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign />{" "}
                  {(
                    parseFloat(bookingData?.total_gst) +
                    parseFloat(bookingData?.total_cost_after_discount)
                  ).toFixed(2)}
                </td>
              </tr>
              {bookingData?.total_tcs > 0 && (
                <tr>
                  <td className="font-semibold">Total TCS :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.total_tcs}
                  </td>
                </tr>
              )}
              {bookingData?.amount_used_in_gift_card > 0 && (
                <tr>
                  <td className="font-semibold">Gift Card Redeemed :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.amount_used_in_gift_card}
                  </td>
                </tr>
              )}
              {bookingData?.amount_used_in_credit_note > 0 && (
                <tr className="text-green-600">
                  <td className="font-semibold">Credit Note Used :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.amount_used_in_credit_note}
                  </td>
                </tr>
              )}
              <tr className="border-t font-semibold text-sky-600">
                <td className="font-semibold">Amount to Pay :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_amount_to_pay}
                </td>
              </tr>
              <tr className="text-green-600">
                <td className="font-semibold">Amount Paid :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.total_amount_paid}
                </td>
              </tr>
              {bookingData?.amount_deducted > 0 && (
                <tr>
                  <td className="font-semibold">Amount Deducted :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.amount_deducted}
                  </td>
                </tr>
              )}
              {bookingData?.credit_note > 0 && (
                <tr>
                  <td className="font-semibold">Refunded as Credit Note :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.credit_note}
                  </td>
                </tr>
              )}
              {bookingData?.refund_amount > 0 && (
                <tr>
                  <td className="font-semibold">Amount Deducted :</td>
                  <td className="flex items-center justify-end px-4 py-1 font-bold">
                    <FaRupeeSign /> {bookingData?.refund_amount}
                  </td>
                </tr>
              )}
              <tr className="border-t font-semibold text-red-600">
                <td className="font-semibold">Remaining Amount :</td>
                <td className="flex items-center justify-end px-4 py-1 font-bold">
                  <FaRupeeSign /> {bookingData?.remaining_amount}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="my-4 rounded-lg border-2 p-4">
        <div className="flex items-center justify-between border-b-2 pb-4">
          <h2 className="text-xl font-bold">Payments</h2>
        </div>
        <div className="my-4">
          <div className="flex justify-between">
            <div className="max-w-[500px] grow basis-1/2">
              <table className="w-full">
                <tbody>
                  <tr className="border-b text-sky-600">
                    <td className="py-2 font-bold">Total Amount to Pay</td>
                    <td className="flex items-center gap-1 py-2">
                      <FaRupeeSign />
                      {bookingData?.total_amount_to_pay}
                    </td>
                  </tr>
                  <tr className="border-b text-green-600">
                    <td className="py-2 font-bold">Amount Paid</td>
                    <td className="flex items-center gap-1 py-2">
                      <FaRupeeSign />
                      {bookingData?.total_amount_paid}
                    </td>
                  </tr>
                  <tr className="text-red-600">
                    <td className="py-2 font-bold">Remaining Amount to Pay</td>
                    <td className="flex items-center gap-1 py-2">
                      <FaRupeeSign />
                      {bookingData?.remaining_amount}
                    </td>
                  </tr>

                  <tr>
                    <td className="py-2 font-bold">Payment Source / Method</td>
                    <td className="flex items-center gap-1 py-2">
                      {bookingData?.payment[0]?.payment_method}
                    </td>
                  </tr>

                  <tr>
                    <td className="py-2 font-bold">Transaction Id</td>
                    <td className="flex items-center gap-1 py-2">
                      {bookingData?.payment[0]?.transaction_id}
                    </td>
                  </tr>
                  <tr>
                    <td className="py-2 font-bold">Payment Screenshot</td>
                    <td className="flex items-center gap-1 py-2">
                      {
                        bookingData?.payment[0]?.screenshot ? (
                          <>
                            {bookingData?.payment[0]?.screenshot
                              .split(".")
                              .pop() === "pdf" ||
                            bookingData?.payment[0]?.screenshot
                              .split(".")
                              .pop() === "PDF" ? (
                              <embed
                                src={`${getUploadsUrl()}/bookings/payment-screenshot/${
                                  bookingData?.payment[0]?.screenshot
                                }`}
                                type="application/pdf"
                                width="100%"
                                height="100%"
                                title="Embedded PDF Viewer"
                              />
                            ) : (
                              <img
                                src={`${getUploadsUrl()}/bookings/payment-screenshot/${
                                  bookingData?.payment[0]?.screenshot
                                }`}
                                alt={`${bookingData?.payment[0]?.screenshot}`}
                              />
                            )}
                          </>
                        ) : (
                          <p>No ScreenShot Attached</p>
                        )
                        //   (bookingData?.payment[0]?.screenshot
                        //     .split(".")
                        //     .pop() === "pdf" ||
                        //     bookingData?.payment[0]?.screenshot
                        //       .split(".")
                        //       .pop() === "PDF")
                        // )(
                        //   <img
                        //     src={`${getUploadsUrl()}/bookings/payment-screenshot/${
                        //       bookingData?.payment[0]?.screenshot
                        //     }`}
                        //     alt=""
                        //   />
                      }
                    </td>
                  </tr>
                  {bookingData?.payment[0]?.screenshot && (
                    <tr>
                      <td></td>
                      <td>
                        <a
                          href={`${getUploadsUrl()}/bookings/payment-screenshot/${
                            bookingData?.payment[0]?.screenshot
                          }`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="rounded-lg border-2 border-blue-500 px-4 py-2 font-bold text-blue-600"
                        >
                          View in New Tab
                        </a>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td className="py-2 font-bold">Lead Converted By</td>
                    <td className="flex items-center gap-1 py-2">
                      {bookingData?.converted_by}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex flex-col gap-1">
              <button
                onClick={viewBooking}
                className={`rounded-lg border py-2 px-4  ${
                  bookingData?.booking_status === 2
                    ? "border-red-500 text-red-500"
                    : "border-blue-500 text-blue-500"
                }`}
              >
                Status : {getVerificationStatus(bookingData?.booking_status)}{" "}
                {bookingData?.booking_status === 3 &&
                  `#${bookingData?.booking_id} Check Now`}
              </button>
              {(auth?.permissions?.includes("PaymentVerification.approve") ||
                auth?.roles?.includes("Superadmin")) && (
                <>
                  {bookingData?.booking_status === 1 && (
                    <>
                      <button
                        onClick={verifyPaymentAndCreateReceipt}
                        className="my-2 rounded-lg bg-blue-500 px-4 py-2 text-white disabled:bg-blue-300"
                      >
                        Verify Payment & create Payment Receipt
                      </button>
                      <button
                        onClick={rejectAndSendForReview}
                        className="rounded-lg bg-red-500 px-4 py-2 text-white"
                      >
                        Reject Payment & send for Review
                      </button>
                    </>
                  )}
                  {bookingData?.booking_status === 2 && (
                    <>
                      <button
                        onClick={verifyPaymentAndCreateReceipt}
                        className="my-2 rounded-lg bg-blue-500 px-4 py-2 text-white disabled:bg-blue-300"
                      >
                        Verify Payment & create Payment Receipt
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="my-4 rounded-lg border-2 p-4">
        <div className="flex items-center justify-between border-b-2 pb-4">
          <h2 className=" text-xl font-bold">Participant Detail</h2>
        </div>
        <div className="my-4">
          <DataTable
            columns={columns}
            tableData={bookingData?.participants}
            height="auto"
            pagination={false}
            responsiveLayout="collapse"
          />
        </div>
      </div>
      <Modal
        isOpen={participantModalIsOpen}
        contentLabel="Update Participant Details"
        onAfterClose={getUpdatedData}
      >
        <div className="relative p-4">
          <h2 className="mb-4 text-xl font-bold">Update Participants Detail</h2>
          <button
            onClick={() => setParticipantModalIsOpen(false)}
            className="absolute right-4 top-4 rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <UpdateParticipants
            bookingData={bookingData}
            participants={bookingData?.participants}
            travelModes={travelModes}
            riders={riders}
            occupancies={occupancies}
            setModalIsOpen={setParticipantModalIsOpen}
          />
        </div>
      </Modal>
      <Modal
        isOpen={paymentModalIsOpen}
        onAfterClose={() => {
          getUpdatedData();
          setRecieptToUpdate(null);
        }}
        contentLabel="Update Payment Reciept"
        className="absolute top-1/2 left-1/2 h-fit min-w-[50%] max-w-[90%] -translate-y-1/2 -translate-x-1/2 rounded-lg bg-white"
      >
        <div className="relative p-4">
          <h2 className="mb-4 text-xl font-bold">
            Update Payment Reciept #{recieptToUpdate?.id}
          </h2>
          <button
            onClick={() => setPaymentModalIsOpen(false)}
            className="absolute right-4 top-4 rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <UpdatePaymentReciept
            setModalIsOpen={setPaymentModalIsOpen}
            receipt={recieptToUpdate}
          />
        </div>
      </Modal>
      <Modal
        isOpen={particpantIdModalIsOpen}
        onAfterClose={() => setActiveParticipantData(null)}
        contentLabel="Update Payment Reciept"
        className="absolute top-1/2 left-1/2 h-fit max-w-[90%] -translate-y-1/2 -translate-x-1/2 rounded-lg bg-white"
      >
        <div className="relative p-4">
          <h2 className="mb-4 text-xl font-bold">
            {activeParticipantData?.first_name}{" "}
            {activeParticipantData?.last_name}
            's ID Proof
          </h2>
          <button
            onClick={() => setParticpantIdModalIsOpen(false)}
            className="absolute right-4 top-4 rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <div className="rounded-lg border-2">
            <img
              src={`${getUploadsUrl()}/bookings/participants/ids/${
                activeParticipantData?.id_proof
              }`}
              alt=""
              className="max-h-[80vh] rounded-lg"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ViewVerificationLink;
