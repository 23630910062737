import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { useEffect, useState } from "react";
import InputLabel from "../../inputs/InputLabel";
import * as Yup from "yup";
import { MdClose } from "react-icons/md";
import { FaPlusSquare, FaRupeeSign } from "react-icons/fa";
import {
  createItineraryPrices,
  getAllItinerariesByPackageId,
  getAllOccupancies,
  getAllRiders,
  getAllTravelModes,
  getPriceByItinerary,
} from "../../../services/PackageService";
import usePackageData from "../../../hooks/usePackageData";
import { arrayToTree } from "../../../../helpers/Helper";
import MultiSelect from "../../inputs/MultiSelect";
import { getAllSpecialDatesOption } from "../../../services/SpecialDateServices";
import moment from "moment";

import SelectSearch from "../../inputs/SelectSearch";
import Swal from "sweetalert2";

function Step5({ onSuccess }) {
  const { packageData } = usePackageData();

  const [itineraries, setItineraries] = useState();
  const [selectedItinerary, setSelectedItinerary] = useState();
  const [travelModes, setTravelModes] = useState();
  const [riders, setRiders] = useState();
  const [occupancies, setOccupancies] = useState();
  const [itineraryPrices, setItineraryPrices] = useState([]);

  const [specialDatesActive, setSpecialDatesActive] = useState(false);
  const [specialDates, setSpecialDates] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [specialPrices, setSpecialPrices] = useState([]);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (completed) return;

    if (itineraryPrices?.length === 0 || specialPrices?.length === 0) return;
    // Create a deep copy of itineraryPrices
    let newItineraryPrices = JSON.parse(JSON.stringify(itineraryPrices));

    specialPrices.forEach((sp) => {
      let tmIndex = newItineraryPrices.findIndex(
        (price) => price.travel_mode_id === sp.travel_mode_id
      );

      let occIndex = newItineraryPrices[tmIndex].riders.findIndex(
        (r) =>
          r.travel_mode_id === sp.travel_mode_id &&
          r.rider_id === sp.rider_id &&
          r.occupancy_id === sp.occupancy_id
      );

      // Update the deep copy with the new special price
      newItineraryPrices[tmIndex].riders[occIndex].special_prices = {
        ...newItineraryPrices[tmIndex].riders[occIndex].special_prices,
        [sp.special_date_id]: {
          price: sp?.price,
          special_date_id: sp?.special_date_id,
          id: sp?.id,
        },
      };
    });

    // Update the state with the modified copy
    setCompleted(true);
    setItineraryPrices(newItineraryPrices);
  }, [itineraryPrices, specialPrices]);

  const handleSpecialDates = (special_prices) => {
    if (special_prices.length > 0) {
      setSpecialDatesActive(true);

      let specialDatesId = [];

      special_prices.forEach((price) => {
        if (!specialDatesId.includes(price?.special_date_id)) {
          specialDatesId.push(price?.special_date_id);
        }
      });

      setSelectedDates(specialDatesId);

      setSpecialPrices(special_prices);
    }

    // formatPrices();
  };

  const handleItineraryChange = (value) => {
    let itinerary = itineraries.filter(
      (itinerary) => itinerary.id === parseInt(value)
    );
    setSelectedItinerary(itinerary[0].id);
    getPriceByItinerary(itinerary[0].id).then((res) => {
      handleSpecialDates(res.data.data.special_prices);
      let treeData = arrayToTree(res.data.data.prices);
      setItineraryPrices(treeData);
    });
  };

  const flatObject = (obj) => {
    let arr = [];

    for (const key in obj) {
      arr.push(obj[key]);
    }

    return arr;
  };

  const addItineraryPrices = async (values) => {
    let specialPries = [];

    let prices = [];
    values.pricing.forEach((price) => {
      prices = [...prices, price.riders];
    });

    let priceList = prices.flat();

    priceList.forEach((price) => {
      price.package_id = packageData.package?.id;
      price.package_itinerary_id = selectedItinerary;

      specialPries.push({
        package_id: packageData.package?.id,
        package_itinerary_id: selectedItinerary,
        travel_mode_id: price?.travel_mode_id,
        occupancy_id: price?.occupancy_id,
        rider_id: price?.rider_id,
        price: flatObject(price?.special_prices),
      });
    });

    specialPries?.forEach((p) => {
      p.price = p.price.filter(
        (price) => price && Object.keys(price).length > 0
      );
    });

    try {
      const res = await createItineraryPrices({
        prices: priceList,
        special_prices: specialPries,
      });
      if (res.data.status) {
        onSuccess();
      }

      console.log(res);
    } catch (error) {
      if (
        error.response.data.message ===
        "Cannot delete this price as it's associated with an active coupon"
      ) {
        Swal.fire({
          title: "Are you sure to delete this price, as it's associated with one/multiple coupon/s?",
          // showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "Delete Forcefully",

        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            createItineraryPrices({
              "force_delete": true,
              prices: priceList,
              special_prices: specialPries,
            }).then((res) => {
              onSuccess();
            });
            Swal.fire("Saved!", "", "success");
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved", "", "info");
          }
        });
      }
    }


    // createItineraryPrices({
    //   prices: priceList,
    //   special_prices: specialPries,
    // }).then((res) => {
    //   onSuccess();
    // });
  };

  useEffect(() => {
    getAllItinerariesByPackageId(packageData.package?.id)
      .then((res) => {
        setItineraries(res.data.data);
      })
      .catch((err) => {
        console.log(err, "erererre---------");
      });

    getAllTravelModes().then((res) => {
      setTravelModes(res.data.data);
    });

    getAllRiders().then((res) => {
      setRiders(res.data.data);
    });

    getAllOccupancies().then((res) => {
      setOccupancies(res.data.data);
    });

    getAllSpecialDatesOption()
      .then((res) => {
        setSpecialDates(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div>
      <div className="my-4">
        <h2 className="text-lg font-bold">Itinerary Pricing</h2>
      </div>
      <div>
        <InputLabel label="Select Itinerary to Update" />
        <SelectSearch
          onChange={handleItineraryChange}
          value={selectedItinerary}
          options={itineraries?.map((itinerary) => ({
            label: `${itinerary.starting_location_name} to ${itinerary.ending_location_name}`,
            value: itinerary.id,
          }))}
        />
      </div>
      {selectedItinerary && (
        <div>
          <Formik
            enableReinitialize
            initialValues={{
              pricing: itineraryPrices,
              special_dates: [],
            }}
            // validationSchema={PackageItineraryCosting}
            onSubmit={(values) => {
              addItineraryPrices(values);
            }}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                <div className="mt-4">
                  <div className="">
                    <div className="my-4 flex items-center gap-12">
                      <label htmlFor="special-dates" className="font-medium">
                        <input
                          type="checkbox"
                          name=""
                          id="special-dates"
                          checked={specialDatesActive}
                          onChange={(e) =>
                            setSpecialDatesActive(e.target.checked)
                          }
                        />{" "}
                        Enable Special Dates Pricing
                      </label>

                      {specialDatesActive && (
                        <div className="basis-[40%] rounded-md">
                          <MultiSelect
                            value={selectedDates}
                            onChange={(values) => setSelectedDates(values)}
                            options={specialDates?.map((sd) => ({
                              label: `${sd?.title} - ${moment(
                                sd?.start_date
                              ).format("DD MMM")} to ${moment(
                                sd?.end_date
                              ).format("DD MMM")}`,
                              value: sd?.id,
                            }))}
                          />
                        </div>
                      )}
                    </div>

                    <div className="">
                      <h2 className="font-bold">Enter Itinerary Price</h2>
                    </div>
                    <FieldArray name="pricing">
                      {({ insert, remove, push }) => (
                        <div>
                          {values.pricing?.length > 0 &&
                            values.pricing?.map((price, index) => (
                              <div
                                className={`rounded-lg border-2 p-4 ${values.pricing?.length === index + 1
                                    ? "mb-1"
                                    : "mb-4"
                                  }`}
                                key={index}
                              >
                                <div className="mb-2 flex items-end justify-between gap-4 font-medium">
                                  {/* //? Select Travel Mode */}
                                  <div className="basis-full lg:basis-auto">
                                    <InputLabel label="Select Travel Mode" />
                                    <SelectSearch
                                      onChange={(value) => {
                                        setFieldValue(
                                          `pricing.${index}.travel_mode_id`,
                                          value
                                        );
                                        values.pricing[index].riders.forEach(
                                          (rider, i) => {
                                            setFieldValue(
                                              `pricing.${index}.riders.${i}.travel_mode_id`,
                                              value
                                            );
                                          }
                                        );
                                      }}
                                      value={parseInt(
                                        values.pricing[index].travel_mode_id
                                      )}
                                      options={travelModes?.map((mode) => ({
                                        label: mode.name,
                                        value: mode.id,
                                        isDisabled: values.pricing.some(
                                          (price) =>
                                            price.travel_mode_id === mode.id
                                        ),
                                      }))}
                                    />

                                    <ErrorMessage
                                      name={`pricing.${index}.travelMode`}
                                    >
                                      {(msg) => (
                                        <div className="error-msg">{msg}</div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                  <button
                                    type="button"
                                    onClick={() => remove(index)}
                                    className="rounded-full border p-2"
                                  >
                                    <MdClose />
                                  </button>
                                </div>
                                <FieldArray name={`pricing[${index}].riders`}>
                                  {({ insert, remove, push }) => (
                                    <div className="after:-top-2 after:left-4 after:h-[calc(100%-4.9rem)]  after:w-[2px] after:bg-gray-300 after:content-[''] lg:relative lg:after:absolute">
                                      {values.pricing[index]?.riders.length >
                                        0 &&
                                        values.pricing[index]?.riders.map(
                                          (rider, indx) => (
                                            <div
                                              className={`relative my-2  mb-5 rounded-lg bg-slate-300 py-6 px-8 after:absolute after:left-4 after:bottom-[-10px] after:z-[-1] after:h-[3px] lg:bg-inherit  lg:after:bottom-10 ${selectedDates.length > 0
                                                  ? "after:w-[86%]"
                                                  : "after:w-[75%]"
                                                } after:bg-gray-300 after:content-['']
                                             `}
                                              // lg:bg-white bg-slate-200 py-6 my-2
                                              key={indx}
                                            >
                                              <div className="flex flex-col items-start gap-4 lg:flex-row lg:items-end">
                                                {/* //? Select Rider */}
                                                <div className="w-full grow lg:w-1/3">
                                                  <InputLabel label="Select Rider" />
                                                  <SelectSearch
                                                    disabled={
                                                      travelModes?.filter(
                                                        (tm) =>
                                                          tm.id ===
                                                          parseInt(
                                                            values.pricing[
                                                              index
                                                            ].travel_mode_id
                                                          )
                                                      )[0]?.has_riders == 0
                                                    }
                                                    onChange={(value) =>
                                                      setFieldValue(
                                                        `pricing.${index}.riders.${indx}.rider_id`,
                                                        value
                                                      )
                                                    }
                                                    value={parseInt(
                                                      values.pricing[index]
                                                        .riders[indx].rider_id
                                                    )}
                                                    options={riders?.map(
                                                      (rider) => ({
                                                        label: rider.name,
                                                        value: rider.id,
                                                      })
                                                    )}
                                                  />

                                                  <ErrorMessage
                                                    name={`pricing.${index}.riders.${indx}.rider_id`}
                                                  >
                                                    {(msg) => (
                                                      <div className="error-msg absolute -bottom-4 left-0">
                                                        {msg}
                                                      </div>
                                                    )}
                                                  </ErrorMessage>
                                                </div>
                                                {/* //? Select Occupancy */}
                                                <div className="w-full grow lg:w-1/3">
                                                  <InputLabel label="Select Occupancy" />
                                                  <SelectSearch
                                                    onChange={(value) =>
                                                      setFieldValue(
                                                        `pricing.${index}.riders.${indx}.occupancy_id`,
                                                        value
                                                      )
                                                    }
                                                    value={parseInt(
                                                      values.pricing[index]
                                                        .riders[indx]
                                                        .occupancy_id
                                                    )}
                                                    options={occupancies?.map(
                                                      (occupancy) => ({
                                                        label: occupancy.name,
                                                        value: occupancy.id,
                                                      })
                                                    )}
                                                  />

                                                  <ErrorMessage
                                                    name={`pricing.${index}.riders.${indx}.occupancy_id`}
                                                  >
                                                    {(msg) => (
                                                      <div className="error-msg absolute -bottom-4 left-0">
                                                        {msg}
                                                      </div>
                                                    )}
                                                  </ErrorMessage>
                                                </div>
                                                {/* // ? Enter Price */}
                                                <div className="w-full grow lg:w-2/3">
                                                  <InputLabel label="Enter Price" />
                                                  <Field
                                                    name={`pricing.${index}.riders.${indx}.price`}
                                                    className="field"
                                                  />
                                                  <ErrorMessage
                                                    name={`pricing.${index}.riders.${indx}.price`}
                                                  >
                                                    {(msg) => (
                                                      <div className="error-msg absolute -bottom-4 left-0">
                                                        {msg}
                                                      </div>
                                                    )}
                                                  </ErrorMessage>
                                                </div>

                                                {specialDatesActive &&
                                                  selectedDates?.map(
                                                    (date, inx) => (
                                                      <div
                                                        key={inx}
                                                        className="w-full grow lg:w-2/3"
                                                      >
                                                        {date}
                                                        <InputLabel
                                                          label={`${specialDates.filter(
                                                            (sd) =>
                                                              sd.id === date
                                                          )[0].title
                                                            } Price`}
                                                        />

                                                        <Field
                                                          name={`pricing.${index}.riders.${indx}.special_prices.${date}.price`}
                                                          type="number"
                                                          onChange={(e) =>
                                                            setFieldValue(
                                                              `pricing.${index}.riders.${indx}.special_prices.${date}`,
                                                              {
                                                                special_date_id:
                                                                  specialDates.filter(
                                                                    (sd) =>
                                                                      sd.id ===
                                                                      date
                                                                  )[0].id,
                                                                price:
                                                                  e.target
                                                                    .value,
                                                              }
                                                            )
                                                          }
                                                          className="field"
                                                        />

                                                        <ErrorMessage
                                                          name={`pricing.${index}.riders.${indx}.price`}
                                                        >
                                                          {(msg) => (
                                                            <div className="error-msg absolute -bottom-4 left-0">
                                                              {msg}
                                                            </div>
                                                          )}
                                                        </ErrorMessage>
                                                      </div>
                                                    )
                                                  )}

                                                {/* //? Set isDefault */}
                                                <div className="w-1/2  grow lg:basis-auto lg:bg-white">
                                                  <div className="mb-1">
                                                    <InputLabel label="Default" />
                                                    <div>
                                                      <label
                                                        htmlFor={`pricing.${index}.riders.${indx}.is_default`}
                                                        className={`relative block h-6 w-12 rounded-full border-2 ${values.pricing[index]
                                                            .riders[indx]
                                                            .is_default
                                                            ? "border-jw-green"
                                                            : "border-gray-400"
                                                          }`}
                                                      >
                                                        <span
                                                          className={`absolute top-1/2 h-[18px] w-5 -translate-y-1/2 rounded-full  ${values.pricing[
                                                              index
                                                            ].riders[indx]
                                                              .is_default
                                                              ? "right-[1px] bg-jw-green"
                                                              : "left-[1px] bg-gray-400"
                                                            }`}
                                                        ></span>
                                                        <Field
                                                          type="checkbox"
                                                          name={`pricing.${index}.riders.${indx}.is_default`}
                                                          id={`pricing.${index}.riders.${indx}.is_default`}
                                                          hidden
                                                          onChange={(e) => {
                                                            values.pricing.forEach(
                                                              (
                                                                price,
                                                                index
                                                              ) => {
                                                                price.riders.forEach(
                                                                  (
                                                                    rider,
                                                                    indx
                                                                  ) => {
                                                                    setFieldValue(
                                                                      `pricing.${index}.riders.${indx}.is_default`,
                                                                      false
                                                                    );
                                                                  }
                                                                );
                                                              }
                                                            );
                                                            setFieldValue(
                                                              `pricing.${index}.riders.${indx}.is_default`,
                                                              e.target.checked
                                                            );
                                                          }}
                                                        />
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>

                                                <button
                                                  type="button"
                                                  onClick={() => remove(indx)}
                                                  className="absolute bottom-[-10px] right-[-10px] z-20 rounded-full border bg-white p-1 lg:static lg:z-0"
                                                >
                                                  <MdClose size={25} />
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        )}
                                      <button
                                        type="button"
                                        className="my-4 flex items-center gap-1 px-4 text-jw-green"
                                        onClick={() =>
                                          push({
                                            travel_mode_id:
                                              values.pricing[index]
                                                .travel_mode_id,
                                            rider_id: "",
                                            occupancy_id: "",
                                            price: "",
                                            is_default: "",
                                          })
                                        }
                                      >
                                        <FaPlusSquare /> Occupancy
                                      </button>
                                    </div>
                                  )}
                                </FieldArray>
                              </div>
                            ))}
                          <button
                            type="button"
                            className="my-4 mt-2 flex items-center gap-1 px-4 text-jw-green"
                            onClick={() =>
                              push({
                                travelMode: "",
                                riders: [
                                  {
                                    rider_id: "",
                                    occupancy_id: "",
                                    price: "",
                                    is_default: "",
                                  },
                                ],
                              })
                            }
                          >
                            <FaPlusSquare /> Travel Mode
                          </button>
                        </div>
                      )}
                    </FieldArray>
                  </div>
                </div>

                <div className="my-4 text-center">
                  <button type="submit" className="btn-primary">
                    submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </div>
  );
}

export default Step5;
