import { ErrorMessage, Field, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { formatNumberToTwoDigit } from "../../../helpers/Helper";
import InputLabel from "../../components/inputs/InputLabel";
import { getAllStateOption } from "../../services/StateServices";
import PhoneNumberInput from "../../../components/inputFields/PhoneInput";
import SelectSearch from "../../components/inputs/SelectSearch";
import {
  checkUserCreditNote,
  checkUserMembership,
} from "../../services/BookingServices";
import { toast } from "react-toastify";
import Collapsible from "../../../hooks/Collapsible";

function UserDetailForm({
  setUserMembership,
  setUserCreditNote,
  selectedIndex,
  setSelectedIndex,
  setPhoneNumberExactDigits,
}) {
  const { values, setFieldValue, errors, touched } = useFormikContext();

  const [stateGst, setStateGst] = useState([]);

  useEffect(() => {
    getAllStateOption().then((res) => {
      setStateGst(res.data.data);
    });
  }, []);

  const countDots = (originalString) => {
    // Step 1: Remove text before the first space
    let indexOfSpace = originalString.indexOf(" ");
    let stringWithoutPrefix = originalString.slice(indexOfSpace + 1);

    // Step 2: Remove the dash "-"
    let stringWithoutDash = stringWithoutPrefix.replace(/\s/g, "");

    // Step 3: Count all dots
    let dots = stringWithoutPrefix.split(".").length - 1;
    return dots;
  };

  const checkMembership = () => {
    if (values.phone.length < 10) return;

    checkUserMembership({
      country_code: values.country_code,
      phone: values.phone,
    })
      .then((res) => {
        setUserMembership(res.data.data);
        setFieldValue("membership_card_number", res?.data?.data?.card_number);
        toast.success("User has membership");
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setFieldValue("membership_card_number", "");
        setUserMembership({});
      });
    checkUserCreditNote({
      country_code: values.country_code,
      phone: values.phone,
    })
      .then((res) => {
        toast.success("User has credit note");
        setUserCreditNote(res.data.data);
        setFieldValue("available_credit_note", res.data.data?.credit_note);
      })
      .catch((err) => {
        console.log(err.response.data.message);
        setFieldValue("available_credit_note", 0);
        setUserCreditNote(0);
      });
  };
  return (
    <>
      <Collapsible
        heading="User Details"
        selectedIndex={selectedIndex}
        index={6}
        setSelectedIndex={setSelectedIndex}
        hasErrors={
          (errors.first_name && touched.first_name) ||
          (errors.email && touched.email) ||
          (errors.phone && touched.phone) ||
          (errors.gst_state && touched.gst_state) ||
          (errors.gst_number && touched.gst_number)
        }
      >
        <div className="my-1 grid grid-cols-12 items-end gap-4 lg:grid-cols-8">
          <div className="col-span-6 lg:col-span-2">
            <InputLabel label="First Name" required={true} />
            <Field className="field" name="first_name" />
            <ErrorMessage name="first_name">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="col-span-6 lg:col-span-2">
            <InputLabel label="Last Name" />
            <Field className="field" name="last_name" />
            <ErrorMessage name="last_name">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>

          <div className="col-span-12 lg:col-span-2">
            <PhoneNumberInput
              phone={`${values.country_code}${values.phone}`}
              onChange={(value, data) => {
                setFieldValue(`country_code`, data.dialCode);
                setFieldValue(`phone`, value.slice(data.dialCode.length));
                setPhoneNumberExactDigits(countDots(data.format));
              }}
              onBlur={checkMembership}
            />
            <ErrorMessage name="phone">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>

          <div className="col-span-12 lg:col-span-2">
            <InputLabel label="Email" required={true} />
            <Field className="field" name="email" />
            <ErrorMessage name="email">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>

          <div className="col-span-12 lg:col-span-2">
            <InputLabel label="Firm Name" instruction="(in case of company)" />
            <Field className="field" name="firm_name" />
            <ErrorMessage name="firm_name">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>

          <div className="col-span-12 lg:col-span-2">
            <InputLabel label="Select State GST" />
            <SelectSearch
              label="State GST"
              options={stateGst?.map((state) => ({
                label: `${formatNumberToTwoDigit(state.gst_code)} - ${
                  state.name
                }`,
                value: state.gst_code,
              }))}
              value={values.gst_state}
              onChange={(value) => setFieldValue("gst_state", value)}
            />

            <ErrorMessage name="gst_state">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="col-span-12 lg:col-span-2">
            <InputLabel label="GST Number" instruction="(if applicable)" />
            <Field
              className="field"
              name="gst_number"
              onChange={(e) => {
                setFieldValue(`gst_number`, e.target.value);
                // setFieldValue("gst_state", e.target.value.slice(0, 2));
              }}
            />
            <ErrorMessage name="gst_number">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="col-span-12 lg:col-span-4">
            <InputLabel
              label="Firm Address"
              instruction="(Registered with GST)"
            />
            <Field
              as="textarea"
              rows="2"
              className="field"
              name="firm_address"
            />
            <ErrorMessage name="firm_address">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>
          <div className="col-span-12 lg:col-span-4">
            <InputLabel label="CC Email Ids" instruction="(Comma Seperated)" />
            <Field as="textarea" rows="2" className="field" name="cc_email" />

            <ErrorMessage name="cc_email">
              {(msg) => <div className="error-msg">{msg}</div>}
            </ErrorMessage>
          </div>
        </div>
      </Collapsible>
    </>
  );
}

export default UserDetailForm;
