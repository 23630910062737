import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { deleteTravelMode } from "../../services/TravelModeServices";
import { toast } from "react-toastify";
import DataTable from "../common/DataTable";
import { getAdminUrl } from "../../../ServiceConfig";
import ComponentHeader from "../common/ComponentHeader";
import { FaPlusSquare } from "react-icons/fa";
import Modal from "react-modal";
import H2 from "../../../components/headings/H2";
import { MdClose } from "react-icons/md";
// import CategoryDeleteModal from "../../lms/CategoryDeleteModal";
import TravelModeDeleteModal from "./TravelModeDeleteModal";

function TravelModeList() {
  const navigate = useNavigate();
  const TravelModeTable = useRef();

  const [selectedTravelMode, setSelectedTravelMode] = useState();
  const [openTravelModeChangeModal, setOpenTravelModeChangeModal] = useState(false);


  const deleteSelectedTravelMode = (data) => {
    let value = JSON.parse(data);
    console.log('value:', value);
    if (!window.confirm("Do You really want to delete this travel mode?")) {
      return;

    }
    setSelectedTravelMode(value)
    setOpenTravelModeChangeModal(true)
    // if (value.usage_count > 0) {
    // }
    // else {
    //   deleteTravelMode(value.id)
    //     .then((res) => {
    //       toast.success(res.data.message);
    //       TravelModeTable.current.deleteRow(value.id);
    //     })
    //     .catch((err) => {
    //       toast.error(err.response.data.message);
    //     });
    // }
  };

  const actionIcons = function (cell, formatterParams, onRendered) {
    const jsonData = JSON.stringify(cell.getRow().getData())
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#39;');
    return `
  <div class="flex items-center gap-3">
      <div class="tm-edit-btn cursor-pointer" data-id=${cell.getRow().getData().id
      }>
          <i class="fas fa-edit"></i>
      </div>
      <div class="tm-delete-btn cursor-pointer text-red-500" data-id="${jsonData}">
        <i class="fas fa-trash-alt"></i>
      </div>
  </div>
  `;
  };

  const columns = [
    {
      formatter: "responsiveCollapse",
      width: 30,
      minWidth: 30,
      responsive: 0,
      hozAlign: "center",
      resizable: false,
      headerSort: false,
    },
    {
      title: "Id",
      field: "id",
      responsive: 0,
      width: "50",
      resizable: true,
      vertAlign: "middle",
    },
    {
      title: "Name",
      field: "name",
      minWidth: "100",
      vertAlign: "middle",
      responsive: 0,
      sorterParams: {
        locale: true,
        alignEmptyValues: "top",
      },
      resizable: true,
    },
    {
      title: "Has Riders",
      field: "has_riders",
      resizable: true,
      vertAlign: "middle",
      formatter: function (cell, formatterParams, onRendered) {
        if (cell.getValue() === 1) {
          return "Yes";
        } else {
          return "No";
        }
      },
    },
    // {
    //   title: "Usage Count",
    //   field: "usage_count",
    //   resizable: true,
    //   vertAlign: "middle",

    // },

    {
      formatter: actionIcons,
      headerSort: false,
      width: 80,
      responsive: 0,
      hozAlign: "center",
      vertAlign: "middle",
    },
  ];

  useEffect(() => {
    $(document).on("click", ".tm-edit-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let cell = $(this).attr("data-id");
      navigate(`/travel-mode/edit/${cell}`);
    });

    $(document).on("click", ".tm-delete-btn", function (e) {
      e.stopPropagation();
      e.preventDefault();
      let dataId = $(this).attr("data-id");
      // console.log(dataId, 'dataid')
      deleteSelectedTravelMode(dataId);
    });

    return () => {
      $(document).off("click", ".tm-edit-btn");
      $(document).off("click", ".tm-delete-btn");
    };
  }, []);

  return (
    <div className="flex h-full flex-col">
      <ComponentHeader className="border-b">
        <H2>Travel Modes</H2>
        <Link
          to="/travel-mode/add"
          className="btn-primary flex items-center gap-1 whitespace-nowrap"
        >
          <FaPlusSquare /> Add New
        </Link>
      </ComponentHeader>
      <div className="h-full">
        <DataTable
          ref={TravelModeTable}
          ajaxUrl={`${getAdminUrl()}/travelModes`}
          columns={columns}
          search={true}
          responsiveLayout="collapse"
        />
      </div>
      <Modal
        isOpen={openTravelModeChangeModal}
        contentLabel="Delete Category Leads"
        // onAfterClose={getUpdatedData}
        className="absolute top-1/2 left-1/2 h-fit -translate-y-1/2 -translate-x-1/2 rounded-lg bg-white"
      >
        <div className="relative p-4">
          <div className="lg:mb-1 mb-4">
            <h2 className="text-xl font-bold">Delete Travel Mode</h2>
            <p>({selectedTravelMode?.name})</p>
          </div>
          <button
            onClick={() => setOpenTravelModeChangeModal(false)}
            className="absolute right-4 top-4 cursor-pointer rounded-full border-2 p-2"
          >
            <MdClose />
          </button>
          <TravelModeDeleteModal selectedTravelMode={selectedTravelMode} setOpenTravelModeChangeModal={setOpenTravelModeChangeModal} travelModeTable={TravelModeTable} />
          {/* <CategoryDeleteModal selectedLeadCategory={selectedLeadCategory?.id} leadCategoryTable={leadCategoryTable} setOpenCategoryModal={setOpenCategoryModal} /> */}
        </div>
      </Modal>
    </div>
  );


}

export default TravelModeList;
