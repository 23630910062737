import { admin } from "../../ServiceConfig";

export function getAllTravelModes(query, per_page = 500) {
    return admin.get(`/travelModes?query=${query}&per_page=${per_page}`)
}
export function getAllTravelModesOption() {
    return admin.get(`/allTravelModesOption`)
}
export function createTravelMode(data) {
    return admin.post(`/travelModes`, data)
}
export function updateTravelMode(id, data) {
    return admin.put(`/travelModes/${id}`, data)
}
export function deleteTravelMode(id, data) {
    return admin.delete(`/travelModes/${id}`, { data: data })
}
export function getTravelModeById(id) {
    return admin.get(`/travelModes/${id}`)
}

